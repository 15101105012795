import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import Tabs, { TabHeader, NavItem, TabContent, TabPane } from '../../../../components/ui/tabs/layout-one'
import { SectionWrap } from './style'
import { MdDone } from "react-icons/md";

const TabsSection = () => {
    return (
        <SectionWrap>
            <div fluid className="service-bgcolor service1">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="headstyle">
                                {/* <Heading as="h4">Connected Workplace</Heading> */}
                                <span className="commandash"></span>
                                <span className="headtag">Lorem Ipsum is simply dummy</span>
                            </div>
                            <p>Blockchain technology is a decentralized, distributed ledger that stores the record of 
                                ownership of digital assets. Any data stored on blockchain is unable to be modified,
                                 making the technology a legitimate disruptor for industries like payments, cybersecurity 
                                 and healthcare. Discover more on what it is,how it’s used and its history.</p>
                        </Col>
                    </Row>
                    <Row className="section-space">
                        <Col lg={6} className="service-img">
                            <img src={"../../../../block-chain.svg"} alt="teams"></img>
                        </Col>
                        <Col lg={6}>
                            <h5><span>Why Is Blockchain Important?</span></h5>
                            <p>Blockchain is an especially promising and revolutionary technology because 
                                it helps reduce security risks, stamp out fraud and bring transparency in a scalable way.
                                Popularized by its association with cryptocurrency and NFTs, blockchain technology has since
                                 evolved to become a management solution for all types of global industries. Today, you can find 
                                 blockchain technology providing transparency for the food supply chain, securing healthcare data,
                                  innovating gaming and overall changing how we handle data and ownership on a large scale.</p>
                            {/* <ul>
                                <li><MdDone className="Tickmark" /> Migrate existing applications and infrastructure to Cloud</li>
                                <li><MdDone className="Tickmark" /> Security and compliance</li>
                                <li><MdDone className="Tickmark" /> integrated Office 365 apps</li>
                                <li><MdDone className="Tickmark" /> Tailor your workspace</li>
                            </ul> */}
                            {/* <p>    Teams gives you one place to have conversations, host meetings, share files and collaborate on documents, and get work
                        done with teams across the organization.</p> */}
                        </Col>
                    </Row>
                    <Row className="section-space">
                        <Col lg={6} className="order-lg-1 order-2">
                            <h5><span>Blockchain Applications</span></h5>
                            <p>Blockchain’s most well-known use (and maybe most controversial) is in cryptocurrencies. 
                                Cryptocurrencies are digital currencies (or tokens), like Bitcoin, Ethereum or Litecoin, 
                                that can be used to buy goods and services. Just like a digital form of cash, crypto can be 
                                used to buy everything from your lunch to your next home. Unlike cash, crypto uses blockchain
                                 to act as both a public ledger and an enhanced cryptographic security system, so online transactions
                                  are always recorded and secured.</p>
                            {/* <ul>
                                <li><MdDone className="Tickmark" /> Anywhere access</li>
                                <li><MdDone className="Tickmark" /> Untethered mobility</li>
                                <li><MdDone className="Tickmark" /> Seamless collaboration</li>
                                <li><MdDone className="Tickmark" /> Controls for security</li>
                            </ul> */}
                            {/* <p> OneDrive connects you to all your files in Office 365 so you
                            can share and work together from anywhere while protecting
                        your work.</p> */}
                        </Col>
                        <Col lg={6} className="order-lg-2 order-1 service-img">
                            <img src={"../../../../crypto-currency.svg"} alt="onedrive"></img>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col lg={6} className="service-img">
                            <img src={"../../../../sharepoint.png"} alt="sharepoint"></img>
                        </Col>
                        <Col lg={6}>
                            <h5><span>SharePoint</span></h5>
                            <p> SharePoint connects our workplace with intelligent content management and intranet sites.</p>
                            <ul>
                                <li><MdDone className="Tickmark" /> Work together wherever you are inside or outside</li>
                                <li><MdDone className="Tickmark" /> Inform your audiences and engage people across</li>
                                <li><MdDone className="Tickmark" /> Collective knowledge across content and people</li>
                                <li><MdDone className="Tickmark" /> Redefine how we transform business processes and manage content</li>
                            </ul>
                        SharePoint enables us to deliver a mobile and intelligent intranet to connect our people,teams, and organization.
                    </Col>
                    </Row> */}
                </Container>
            </div>
            {/* <div fluid className="service-bgcover service2">
                <Container pt="30px">
                    <Row className="cloudsection">
                        <Col lg={12} className="leftsection pb-4">
                            <Heading as="h4" color="white">Digital Marketing</Heading>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className="mb-3">
                            <h6 className="mb-1"><span> <MdDone className="Tickmark" /> Web Design</span></h6>
                            <ul>
                                <li> Web design is the process of planning,conceptualizing, and arranging content intended for the Internet.
                        </li>
                            </ul>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <h6 className="mb-1"><span> <MdDone className="Tickmark" /> Digital Assets</span></h6>
                            <ul>
                                <li>Branding has been constantly evolved from its earlier inception and has got transformed as the digital branding
                        </li>
                            </ul>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <h6 className="mb-1"><span> <MdDone className="Tickmark" /> Search Engine Optimization(SEO)</span></h6>
                            <ul>
                                <li> is the practice of getting targeted traffic to a website from a search engine’s organic rankings.It is a critical part of internet marketing and generates page profits for businesses.
                        </li>
                            </ul>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <h6 className="mb-1"><span> <MdDone className="Tickmark" /> Search Engine Marketing(SEM)</span></h6>
                            <ul>
                                <li>Search Engine Marketing (SEM) or Google Adwords or Pay per Click (PPC) management is the most effective methods to promote a customer's website
                        </li>
                            </ul>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <h6 className="mb-1"><span> <MdDone className="Tickmark" /> Social Media Optimization (SMO)</span></h6>
                            <ul>
                                <li>Social Media Optimization (SMO) was designed to drive the traffic from social media platforms such as social bookmarking sites and social networking sites such as Facebook, Twitter, LinkedIn, Instagram and YouTube.
                        </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div> */}
        </SectionWrap>
    )
}

export default TabsSection
